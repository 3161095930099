<template>
  <div>
    <button class="button" @click="menuButtonPressed">
      <img :src="icon" />
      <p>{{ text }}</p>
      <img class="right" :src="require('../../../assets/icons/icn_arrow.svg')" />
    </button>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted, onUnmounted } from '@vue/runtime-core'
export default {
  name: 'DiaSelectButton',
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  setup(props) {
    function menuButtonPressed() {
    }

    return {
      menuButtonPressed,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.button {
  height: rem(46);
  width: 100%;
  background-color: white;
  color: #373B52;
  border: none;
  border-radius: rem(8);
  box-shadow: rem(2) rem(2) 0 0 rgba(0,0,0,0.1);
  font-size:rem(16);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(19);
  padding: 0;
  margin-bottom: rem(8);
  display: flex;
  cursor: pointer;

  img {
    width: rem(24);
    height: rem(24);
    margin: rem(11) 0 rem(11) rem(16);
    padding: rem(2);
  }

  p {
    width: 100%;
    height: rem(19);
    margin: rem(13) 0 rem(14) rem(16);
    color:#373B52;
    font-family: var(--font-primary);
    text-align: left;
  }
  .right {
    float: right;
    margin: rem(11) rem(16) rem(11) 0;
  }
}

</style>

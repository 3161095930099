// const missionSlideTypes = {
//   MISSION_TEXT: 1,
//   MISSION_PICTURE: 2,
//   MISSION_VIDEO: 3,
//   MISSION_POLL: 4,
//   MISSION_TRUE_OR_FALSE: 5,
//   MISSION_DRAG_AND_DROP: 6,
//   MISSION_SELECT_SINGLE_TEXT: 7,
//   MISSION_SELECT_MULTI_TEXT: 8,
//   MISSION_SELECT_SINGLE_PICTURE: 9,
//   MISSION_SELECT_MULTI_PICTURE: 10,
//   MISSION_MAZE: 11,
//   MISSION_NETIQUETTE: 12,
//   MISSION_REAL_OR_PHISHING: 13,
//   MISSION_DILEMMA: 14
// }

export const missionSlideTypes = [
  {
    id: 1,
    name: 'Tekst',
    isStatic: 1
  },
  {
    id: 2,
    name: 'Afbeelding',
    isStatic: 1
  },
  {
    id: 3,
    name: 'Video',
    isStatic: 1
  },
  {
    id: 4,
    name: 'Stemmen',
    isStatic: 0
  },
  {
    id: 5,
    name: 'Waar of niet waar',
    isStatic: 0
  },
  {
    id: 6,
    name: 'Drag & Drop',
    isStatic: 0
  },
  {
    id: 7,
    name: 'Selecteer antwoord (1 antwoord mogelijk)',
    isStatic: 0
  },
  {
    id: 8,
    name: 'Selecteer antwoord (meerdere antwoorden mogelijk)',
    isStatic: 0
  },
  {
    id: 9,
    name: 'Selecteer afbeelding (1 antwoord mogelijk)',
    isStatic: 0
  },
  {
    id: 10,
    name: 'Selecteer afbeeldingen (meerdere antwoorden mogelijk)',
    isStatic: 0
  },
  {
    id: 11,
    name: 'Labyrint',
    isStatic: 0
  },
  {
    id: 12,
    name: 'Netiquette',
    isStatic: 0
  },
  {
    id: 13,
    name: 'Echt of phishing',
    isStatic: 0
  },
  {
    id: 14,
    name: 'Dilemma',
    isStatic: 0
  }
]

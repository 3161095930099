
export const missionCategoryIconMap =
{
  0: require('../../../assets/icons/icn_aligned-left.svg'),
  1: require('../../../assets/icons/icn_aligned-left.svg'),
  2: require('../../../assets/icons/icn_picture.svg'),
  3: require('../../../assets/icons/icn_picture.svg'),
  4: require('../../../assets/icons/icn_aligned-left.svg'),
  5: require('../../../assets/icons/icn_aligned-left.svg'),
  6: require('../../../assets/icons/icn_dilemma.svg'),
}

export function getSlideIcon(slideTypeId) {
  if (missionCategoryIconMap[slideTypeId]) {
    return missionCategoryIconMap[slideTypeId]
  }
  return missionCategoryIconMap[0]
}
